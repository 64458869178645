//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Button, Icon, Message } from "semantic-ui-react";
import { ImportDataInstructions as ApiImportDataInstructions } from "../../api/lib";
import { HelpUrl } from "../../HelpUrl";
import { TextLink } from "../../ui/lib";

const CodeContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  pre {
    flex-grow: 1;
  }
  button {
    margin-left: 2em !important;
    flex-grow: 0;
  }
`;

interface IIArangoimportInstructionsViewArgs {
  instructions?: ApiImportDataInstructions;
  copiedExportCode: boolean;
  copiedImportCode: boolean;
  isPrivateEndpoint?: boolean;
  onCopiedImportCode: () => void;
  onCopiedExportCode: () => void;
  onClickClientTools: () => void;
}

const ArangoimportInstructionsView = ({ ...args }: IIArangoimportInstructionsViewArgs) => {
  if (args.instructions) {
    const export_dump = "arangodump --all-databases true \\\n  --output-directory dump\n  <other options for connecting to your local database>";
    const import_dump = args.instructions.import_dump ? args.instructions.import_dump.join("\n") : undefined;

    return (
      <div>
        <p className="para">Import data to ArangoGraph Insights Platform from an existing arangodb instance.</p>
        <h3 className="heading-3">Step 1</h3>
        <p className="para">
          Download and install <TextLink onClick={args.onClickClientTools}>ArangoDB client tools</TextLink>.
        </p>
        <h3 className="heading-3">Step 2</h3>
        <p className="para">Export data from existing ArangoDB instance using.</p>

        <CodeContainer>
          <SyntaxHighlighter languages="bash">{export_dump}</SyntaxHighlighter>
          <CopyToClipboard text={export_dump} onCopy={args.onCopiedExportCode}>
            <Button basic disabled={!export_dump} icon={args.copiedExportCode ? "checkmark" : "copy"} labelPosition="right" content="Copy" />
          </CopyToClipboard>
        </CodeContainer>
        <p className="para">
          For all CLI options, consult the{" "}
          <a className="text-link" target="_blank" href={HelpUrl.arango_dump_cli_options_page} rel="noreferrer">
            <b>arangodump</b> documentation
          </a>
          .
        </p>
        <h3 className="heading-3">Step 3</h3>
        <p className="para">Import data into this ArangoDB instance on ArangoGraph Insights Platform.</p>
        {args.isPrivateEndpoint && (
          <Message>
            <Icon name="info" />
            For private endpoint deployments, make sure the endpoint can be reached by your arangostore machine
          </Message>
        )}
        <CodeContainer>
          <SyntaxHighlighter languages="bash">{import_dump}</SyntaxHighlighter>
          <CopyToClipboard text={import_dump} onCopy={args.onCopiedImportCode}>
            <Button basic disabled={!import_dump} icon={args.copiedImportCode ? "checkmark" : "copy"} labelPosition="right" content="Copy" />
          </CopyToClipboard>
        </CodeContainer>
        <p className="para">
          For all CLI options, consult the{" "}
          <a className="text-link" target="_blank" href={HelpUrl.arango_restore_cli_options_page} rel="noreferrer">
            <b>arangorestore</b> documentation
          </a>
          .
        </p>
      </div>
    );
  }

  return <div />;
};

interface IArangorestoreInstructionsProps {
  disabled?: boolean;
  instructions?: ApiImportDataInstructions;
  isPrivateEndpoint?: boolean;
  onClickClientTools: () => void;
}

interface IArangorestoreInstructionsState {
  copiedExportCode: boolean;
  copiedImportCode: boolean;
  selectedCode?: string[];
}

class ArangorestoreInstructions extends Component<IArangorestoreInstructionsProps, IArangorestoreInstructionsState> {
  state = {
    caCertificate: undefined,
    copiedExportCode: false,
    copiedImportCode: false,
    selectedCode: undefined,
    copiedArangoimportExportCode: false,
    copiedArangoimportImportCode: false,
  } as IArangorestoreInstructionsState;

  onCopiedExportCode = () => {
    this.setState({ copiedExportCode: true });
  };

  onCopiedImportCode = () => {
    this.setState({ copiedImportCode: true });
  };

  componentDidMount() {
    if (this.props.instructions) {
      this.setState({ copiedExportCode: false });
      this.setState({ copiedImportCode: false });
    }
  }

  render() {
    return (
      <ArangoimportInstructionsView {...this.props} {...this.state} onCopiedExportCode={this.onCopiedExportCode} onCopiedImportCode={this.onCopiedImportCode} />
    );
  }
}

export default ArangorestoreInstructions;
